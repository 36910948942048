<template>
  <div id="sonic.overview.project">
    <b-card
      v-for="project in sortedProjects"
      :key="project.project_id"
      :id="project.project_id"
      class="shadow mt-2 mb-3"
    >
    <b-row class="d-flex justify-content-between">
      <b-col @click="goToProject('sonic.input', project.project_id)"><b-card-title>{{ project.name }}</b-card-title></b-col>
      <!-- <b-col class="text-right"><b-icon icon="trash" @click="deleteProject(project.project_id)" variant="danger" class="h5"></b-icon></b-col> -->
    </b-row>
      <b-card-text @click="goToProject('sonic.input', project.project_id)">{{ project.description }}</b-card-text>
      <template v-slot:footer @click="goToProject('sonic.input', project.project_id)">
        <span class="text-muted">{{
          project.last_updated | moment("from", "now")
        }}</span>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "sonic.overview.project",
  computed: {
    ...mapGetters({
      projects: "getSonicProjects",
    }),
    sortedProjects() {
      return this.projects
        .slice(0)
        .sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
    },
  },
  methods: {
    goToProject(name, projectId) {
      this.$router.push({ name: name, params: { id: projectId } });
    },
    deleteProject(projectId) {
      if (confirm("Are you sure you want to delete the project")) {
        console.log(`Project ${projectId} will be deleted`)
      } else {
        console.log("Nothing happend")
      }
    }
  },
};
</script>

<style>
</style>