<template>
  <div id="projectName">
    <b-form-group class="mb-2" id="name-group">
      <label for="name-live">{{$t('sonic.form.project.name.label')}}:</label>
      <b-input-group>
        <b-form-input
          id="name-live"
          type="text"
          v-model="$v.name.$model"
          :state="validateState('name')"
          aria-describedby="name-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.name.$anyError" id="name-feedback">
        <small
          v-if="!$v.name.required"
          class="form-text text-danger"
        >{{$t('sonic.form.project.name.required')}}</small>
        <small
          v-if="!$v.name.minLength"
          class="form-text text-danger"
        >{{$t('sonic.form.project.name.minLength', { min: getLowerBoundary() })}}</small>
        <small
          v-if="!$v.name.maxLength"
          class="form-text text-danger"
        >{{$t('sonic.form.project.name.maxLength', { max: getUpperBoundary() })}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import logic from '@/scripts';
export default {
  name: "sonic.project.name",
  computed: {
    ...mapGetters({
      project: 'getSonicProjectForm'
    }),
    name: {
      get() {
        return this.project.name
      },
      set(value) {
        this.$store.commit("setSonicProjectFormName",value)
      }
    },
  },
  
  validations: {
    name: {
      required,
      minLength() {
        return (
          logic.input.sonic.project.name.minLength(this.project.name)
        );
      },
      maxLength() {
        return (
          logic.input.sonic.project.name.maxLength(this.project.name)
        );
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundary() {
      return logic.input.sonic.project.name.min
    },
    getUpperBoundary() {
      return logic.input.sonic.project.name.max
    }
  }
};
</script>

<style>
</style>