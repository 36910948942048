<template>
  <div id="projectDescription">
    <b-form-group class="mb-2" id="description-group">
      <label for="description-live">{{$t('sonic.form.project.description.label')}}:</label>
      <b-input-group>
        <b-form-textarea
          id="description-live"
          type="text"
          v-model="$v.description.$model"
          :state="validateState('description')"
          aria-describedby="description-feedback"
          rows="3"
        ></b-form-textarea>
      </b-input-group>
      <div v-if="$v.description.$anyError">
        <small
          v-if="!$v.description.required"
          class="form-text text-danger"
        >{{$t('sonic.form.project.description.required')}}</small>
        <small
          v-if="!$v.description.minLength"
          class="form-text text-danger"
        >{{$t('sonic.form.project.description.minLength', { min: getLowerBoundary() })}}</small>
        <small
          v-if="!$v.description.maxLength"
          class="form-text text-danger"
        >{{$t('sonic.form.project.description.maxLength', { max: getUpperBoundary() })}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import logic from '@/scripts';
export default {
  name: "sonic.project.description",
  computed: {
    ...mapGetters({
      form: 'getSonicProjectForm',
    }),
    description: {
      get() {
        return this.form.description
      },
      set(value) {
        this.$store.commit("setSonicProjectFormDescription",value)
      }
    },
  },
  
  validations: {
    description: {
      required,
      minLength() {
        return (
          logic.input.sonic.project.description.minLength(this.form.description)
        );
      },
      maxLength() {
        return (
          logic.input.sonic.project.description.maxLength(this.form.description)
        );
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundary() {
      return logic.input.sonic.project.description.min
    },
    getUpperBoundary() {
      return logic.input.sonic.project.description.max
    }
  }
};
</script>

<style>
</style>