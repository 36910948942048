<template>
  <div id="sonic.overview.project.create">
    <b-modal
      id="modal-create-project"
      scrollable
      no-close-on-backdrop
      :title="$t('sonic.form.project.title')"
      @ok="ok"
      @cancel="cancel"
    >
    <name-input />
    <description-input />
      <template v-slot:modal-footer>
        <b-button type="button" variant="success" :disabled="!isValid" @click="ok()">
          <div>{{$t('sonic.form.project.buttons.submit')}}</div>
        </b-button>
        <b-button
          type="button"
          variant="danger"
          @click="cancel()"
        >{{$t('sonic.form.project.buttons.cancel')}}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logic from '@/scripts'
import nameInput from "../form/project/name";
import descriptionInput from "../form/project/description";
export default {
  name: "sonic.overview.project.create",
  components: {
    nameInput,
    descriptionInput
  },
  computed: {
    ...mapGetters({
      project: 'getSonicProjectForm',
    }),
    isValid() {
      return logic.input.sonic.project.validate(this.project)
    }
  },
  methods: {
    ok() {
      this.$store.dispatch('createSonicProject')
      this.$nextTick(() => {
        this.$bvModal.hide('modal-create-project')
      })
    },
    cancel(){
      this.$store.dispatch('clearSonicProjectForm')
      this.$nextTick(() => {
        this.$bvModal.hide('modal-create-project')
      })
    },
  }
};
</script>

<style>
</style>