<template>
  <div id="sonic.overview.index">
    <div v-if="isLoading">
      <div class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
    </div>
    <div v-else>
      <div v-if="count > 0">
        <projects />
      </div>
      <div v-else>
        <b-card class="mt-2 mb-2" @click="openCreateProjectForm()">
          <b-card-title>{{$t('sonic.empty.title')}}</b-card-title>
          <b-card-text>{{$t('sonic.empty.description')}}</b-card-text>
        </b-card>
      </div>
    </div>
    <project-form />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import projects from "./projects";
import projectForm from "./form";
export default {
  name: "sonic.overview.index",
  components: {
    projects,
    projectForm,
  },
  computed: {
    ...mapGetters({
      count: "getSonicProjectsCount",
      isLoading: "isSonicLoading",
    }),
  },
  methods: {
    openCreateProjectForm() {
      this.$root.$emit("bv::show::modal", "modal-create-project");
    },
  },
};
</script>

<style>
</style>